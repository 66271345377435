import { OnboardingUser } from '@doc-abode/data-models';
import {
    ArrowCircleLeft,
    CheckCircle,
    ExclamationCircle,
    MinusCircle,
    PlusCircle,
    ShieldCheck,
    ShieldExclamation,
    X,
    XCircle,
} from 'heroicons-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

import api from '../../../utils/api';
import {
    inviteStatusColors,
    onboardingStatusColors,
    passwordStatusColors,
    prescreenStatusColors,
} from '../../../utils/colors';
import { prettyDate, prettyDateTime } from '../../../utils/shared';
import Loader from '../../shared/Loader';
import ManagePermissions from '../../shared/ManagePermissions';
import Tag from '../../shared/Tag';
import UserOnboardingDetails from './UserOnboardingDetails';

const Verified = ({ verified }) => {
    const color = verified ? 'green' : 'red';
    return (
        <span
            className={`inline-block align-bottom ml-1 rounded text-xs px-1 bg-${color}-100 text-${color}-700 border border-${color}-700`}
        >
            {!verified && 'not'} verified
        </span>
    );
};

const UserDetails = () => {
    const [userLoading, setUserLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [didError, setDidError] = useState(false);
    const [verifyingPhoneNumber, setVerifyingPhoneNumber] = useState(false);
    const [verifyingEmail, setVerifyingEmail] = useState(false);
    const [resettingUser, setResettingUser] = useState(false);
    const [updatingPermissions, setUpdatingPermissions] = useState(false);
    const [updatingUser, setUpdatingUser] = useState(false);
    const [pageErrors, setPageErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [onboarding, setOnboarding] = useState({});
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [allOrganisations, setAllOrganisations] = useState([]);

    const { userId } = useParams();
    const navigate = useNavigate();

    const getUserData = useCallback(async () => {
        setUserLoading(true);

        if (!userId) return;

        try {
            const userResponse = await api.getUser(userId);
            setUserData({ ...userResponse, onboarding: null });
            if (userResponse.onboarding) {
                const onboardingUser = new OnboardingUser();
                onboardingUser.import(userResponse.onboarding);
                setOnboarding(onboardingUser);
            }
        } catch (err) {
            console.error(err.response);
            setDidError(err.response?.data?.error || 'Unknown error');
        }

        setUserLoading(false);
    }, [userId]);

    useEffect(() => {
        getUserData();
    }, [getUserData]);

    useEffect(() => {
        const getAllOrganisations = async () => {
            try {
                const response = await api.getAllConfigs();
                setAllOrganisations(response);
            } catch (err) {
                console.error(err.response);
                setDidError(err.response?.data?.error || 'Unknown error');
            }
        };

        getAllOrganisations();
    }, []);

    const verifyPhoneNumber = async () => {
        setVerifyingPhoneNumber(true);
        setPageErrors({ ...pageErrors, verifyPhoneNumber: null });

        try {
            await api.verifyUser(userId, 'phone_number', { isLegacy: false });
            setSuccessMessage("User's phone number has been verified.");
        } catch (err) {
            console.error(err);
            setPageErrors({ ...pageErrors, verifyPhoneNumber: err.message });
        }

        getUserData();
        setVerifyingPhoneNumber(false);
    };

    const verifyEmail = async () => {
        setVerifyingEmail(true);
        setPageErrors({ ...pageErrors, verifyEmail: null });

        try {
            await api.verifyUser(userId, 'email', { isLegacy: false });
            setSuccessMessage("User's email address has been verified.");
        } catch (err) {
            console.error(err);
            setPageErrors({ ...pageErrors, verifyEmail: err.message });
        }

        getUserData();
        setVerifyingEmail(false);
    };

    const resetUser = async () => {
        setResettingUser(true);
        setPageErrors({ ...pageErrors, resetUser: null });

        try {
            await api.resetUser(userId, { isLegacy: false });
            setSuccessMessage("User's password has been reset.");
        } catch (err) {
            console.error(err);
            setPageErrors({ ...pageErrors, resetUser: err.message });
        }

        getUserData();
        setResettingUser(false);
    };

    const updatePermissions = async (permissions) => {
        setUpdatingPermissions(true);
        setPageErrors({ ...pageErrors, updatePermissions: null });

        try {
            await api.updatePermissions(userId, permissions);
            setSuccessMessage("User's permissions have been updated.");
        } catch (err) {
            console.error(err);
            setPageErrors({ ...pageErrors, updatePermissions: err.message });
        }

        getUserData();
        setUpdatingPermissions(false);
    };

    const updateUser = async (params) => {
        setUpdatingUser(true);
        setPageErrors({ ...pageErrors, updateUser: null });

        try {
            await api.updateUser(userId, params);
            setSuccessMessage('User has been updated.');
        } catch (err) {
            console.error(err);
            setPageErrors({ ...pageErrors, updateUser: err.message });
        }

        getUserData();
        setUpdatingUser(false);
    };

    const {
        userName,
        status,
        createdAt,
        email,
        phoneNumber,
        emailVerified,
        phoneNumberVerified,
        profile: { organisations = {}, hcpTypes = [] } = {},
        invites = [],
        groups = [],
    } = userData;

    const {
        attributes: {
            onboardingStatus,
            prescreenResult,
            approvedBy,
            approvedAt,
            completedAt,
            rejectedAt,
            rejectedBy,
        } = {},
    } = onboarding;

    const showVerifyPhoneNumber = !phoneNumberVerified && phoneNumber;
    const showVerifyEmail = !emailVerified && email;
    const showResetPassword =
        status === 'FORCE_CHANGE_PASSWORD' || emailVerified || phoneNumberVerified;

    const availableOrgs = allOrganisations
        .slice()
        .filter(({ org }) => org !== 'doc-abode-base')
        .filter(({ org }) => !groups.includes(`org-${org}`))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ org, name }) => ({ label: name, value: org }));

    const currentOrgs = allOrganisations.slice().filter(({ org }) => groups.includes(`org-${org}`));

    const hcpTypeOptions =
        allOrganisations.find(({ org }) => org === 'doc-abode-base')?.hcpTypes || [];

    // Add custom HCP types for each organisation to which the user belongs to the list of options
    currentOrgs.forEach(({ hcpTypes: customHcpTypes = [] }) => {
        customHcpTypes.forEach((customHcpType) => {
            if (
                !hcpTypeOptions.find(({ value }) => customHcpType.value === value) &&
                customHcpType.enabled
            ) {
                hcpTypeOptions.push(customHcpType);
            }
        });
    });

    hcpTypeOptions.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <section className="p-6 h-full">
            <button
                className="rounded py-1 px-2 mb-4 bg-teal-600 font-light text-white hover:bg-teal-700 flex text-sm items-center"
                onClick={() => navigate(-1)}
            >
                <ArrowCircleLeft className="mr-1" size={20} />
                Back
            </button>
            <h1 className="mb-4 text-blue-800 text-3xl font-thin">{userId}</h1>
            {userLoading && (
                <div className="text-teal-600 flex justify-center my-5">
                    <Loader size={10} />
                </div>
            )}
            {didError && (
                <p className="text-sm text-red-700 mb-1 flex items-center">
                    <ExclamationCircle className="mr-1" />
                    Error loading user details: {didError}
                </p>
            )}
            {userData.userName && (
                <>
                    <dl className="p-3 border-t border-gray-300 flex flex-col sm:flex-row sm:flex-wrap text-sm">
                        <dt className="sm:w-1/3 font-bold">Full name</dt>
                        <dd className="sm:w-2/3 py-1">{userName}</dd>
                        <dt className="sm:w-1/3 font-bold">Email address</dt>
                        <dd className="sm:w-2/3 py-1">
                            {email || 'N/A'} {email && <Verified verified={emailVerified} />}
                        </dd>
                        <dt className="sm:w-1/3 font-bold">Phone number</dt>
                        <dd className="sm:w-2/3 py-1">
                            {phoneNumber || 'N/A'}{' '}
                            {phoneNumber && <Verified verified={phoneNumberVerified} />}
                        </dd>
                        <dt className="sm:w-1/3 font-bold">Registration date</dt>
                        <dd className="sm:w-2/3 py-1">{prettyDate(createdAt)}</dd>
                        <dt className="sm:w-1/3 font-bold">Password status</dt>
                        <dd className="sm:w-2/3 py-1">
                            <Tag color={passwordStatusColors[status]}>{status}</Tag>
                        </dd>
                    </dl>
                    <div className="mb-4">
                        <h2 className="mb-4 text-blue-800 text-2xl font-thin">Organisations</h2>
                        <table className="text-sm w-full border-t border-gray-300">
                            <thead>
                                <tr>
                                    <th className="font-bold text-left border-b p-2 border-gray-300 bg-gray-100">
                                        Organisation
                                    </th>
                                    <th className="font-bold text-left border-b p-2 border-gray-300 bg-gray-100">
                                        Permissions
                                    </th>
                                    <th className="font-bold text-left border-b p-2 border-gray-300 bg-gray-100">
                                        Status
                                    </th>
                                    <th className="font-bold text-left border-b p-2 border-gray-300 bg-gray-100">
                                        Invited status
                                    </th>
                                    <th className="font-bold text-left border-b p-2 border-gray-300 bg-gray-100 text-red-700">
                                        Remove from org
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(organisations)
                                    .slice()
                                    .sort((a, b) => a[0].localeCompare(b[0]))
                                    .map(([orgId, orgData]) => {
                                        const invite = invites.find(
                                            (invite) => invite.organisation === orgId,
                                        );
                                        return (
                                            <tr key={orgId}>
                                                <td className="border-b p-2 border-gray-300 font-bold">
                                                    {
                                                        allOrganisations.find(
                                                            ({ org }) => org === orgId,
                                                        )?.name
                                                    }
                                                </td>
                                                <td className="border-b p-2 border-gray-300">
                                                    <ManagePermissions
                                                        org={orgId}
                                                        groups={groups}
                                                        updatingPermissions={updatingPermissions}
                                                        updatePermissions={updatePermissions}
                                                    />
                                                </td>
                                                <td className="border-b p-2 border-gray-300">
                                                    {orgData.enabled === true ? (
                                                        <span className="text-green-700 flex items-center">
                                                            <CheckCircle className="mr-1" />{' '}
                                                            <strong>Enabled</strong>
                                                        </span>
                                                    ) : orgData.enabled === false ? (
                                                        <span className="text-red-700 flex items-center">
                                                            <MinusCircle className="mr-1" />{' '}
                                                            <strong>Disabled</strong>
                                                        </span>
                                                    ) : (
                                                        <span className="text-orange-700 flex items-center">
                                                            <ExclamationCircle className="mr-1" />{' '}
                                                            <strong>
                                                                User is not configured properly for
                                                                this organisation
                                                            </strong>
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="border-b p-2 border-gray-300">
                                                    <Tag
                                                        color={
                                                            inviteStatusColors[invite?.inviteStatus]
                                                        }
                                                    >
                                                        {invite?.inviteStatus || 'No invite exists'}
                                                    </Tag>
                                                </td>
                                                <td className="border-b p-2 border-gray-300">
                                                    <button
                                                        className="rounded py-1 px-2 bg-red-700 font-light text-white hover:bg-red-800 flex text-sm items-center"
                                                        onClick={() =>
                                                            updatePermissions({
                                                                removeFromOrg: [orgId],
                                                            })
                                                        }
                                                    >
                                                        {updatingPermissions ? (
                                                            <div className="mr-1">
                                                                <Loader />
                                                            </div>
                                                        ) : (
                                                            <X size={20} className="mr-1" />
                                                        )}
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {availableOrgs.length > 0 && (
                            <>
                                <h3 className="my-2 text-blue-800 text-xl">Add to organisation</h3>
                                <div className="md:w-1/3">
                                    <Select
                                        className="text-sm"
                                        isLoading={updatingPermissions}
                                        isSearchable
                                        options={availableOrgs}
                                        onChange={(option) =>
                                            updatePermissions({
                                                addToOrg: [option.value],
                                            })
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <h2 className="mb-4 text-blue-800 text-2xl font-thin">HCP types</h2>
                    <div className="mb-4">
                        <label className="mb-4 block text-sm">
                            <span className="mb-1 block">HCP type(s)</span>
                            <Select
                                className="text-sm w-full md:w-1/2"
                                classNamePrefix="react-select"
                                isSearchable
                                options={hcpTypeOptions}
                                onChange={(selectedOptions) => {
                                    updateUser({
                                        hcpTypes: selectedOptions.map((option) => option.value),
                                    });
                                }}
                                defaultValue={hcpTypes.map((hcpType) =>
                                    hcpTypeOptions.find((option) => option.value === hcpType),
                                )}
                                isDisabled={updatingUser}
                                isMulti
                            />
                        </label>
                    </div>
                    <h2 className="mb-4 text-blue-800 text-2xl font-thin">Groups</h2>
                    <div className="mb-4">
                        {groups
                            .slice()
                            .sort((a, b) => a.localeCompare(b))
                            .map((group) => (
                                <Tag wrap prettify={false} key={group}>
                                    {group}
                                </Tag>
                            ))}
                    </div>
                    {onboardingStatus && (
                        <>
                            <h2 className="mb-4 text-blue-800 text-2xl font-thin">Onboarding</h2>
                            <dl className="p-3 border-t border-gray-300 flex flex-col sm:flex-row sm:flex-wrap text-sm">
                                <dt className="sm:w-1/3 font-bold">Prescreen result</dt>
                                <dd className="sm:w-2/3 py-1">
                                    <>
                                        <Tag color={prescreenStatusColors[prescreenResult]}>
                                            {prescreenResult}
                                        </Tag>
                                        {prescreenResult === 'APPROVED' &&
                                            approvedAt &&
                                            approvedBy && (
                                                <span className="ml-2">
                                                    by {approvedBy} at {prettyDateTime(approvedAt)}
                                                </span>
                                            )}
                                        {prescreenResult === 'REJECTED' &&
                                            rejectedAt &&
                                            rejectedBy && (
                                                <span className="ml-2">
                                                    by {rejectedBy} at {prettyDateTime(rejectedAt)}
                                                </span>
                                            )}
                                    </>
                                </dd>
                                <dt className="sm:w-1/3 font-bold">Onboarding status</dt>
                                <dd className="sm:w-2/3 py-1">
                                    <Tag color={onboardingStatusColors[onboardingStatus]}>
                                        {onboardingStatus}
                                    </Tag>
                                    {onboardingStatus === 'COMPLETE' && completedAt && (
                                        <span className="ml-2">
                                            at {prettyDateTime(completedAt)}
                                        </span>
                                    )}
                                </dd>
                            </dl>
                            <button
                                className="rounded py-1 px-2 mb-4 ml-3 bg-teal-600 font-light text-white hover:bg-teal-700 flex text-sm items-center"
                                onClick={() => setShowOnboarding(!showOnboarding)}
                            >
                                {showOnboarding ? (
                                    <>
                                        <XCircle className="mr-1" size={20} /> Hide onboarding
                                        details
                                    </>
                                ) : (
                                    <>
                                        <PlusCircle className="mr-1" size={20} /> Show onboarding
                                        details
                                    </>
                                )}
                            </button>
                            {showOnboarding && <UserOnboardingDetails onboarding={onboarding} />}
                        </>
                    )}
                    <h2 className="mb-4 text-blue-800 text-2xl font-thin">Available actions</h2>
                    <div className="flex">
                        {showVerifyPhoneNumber && (
                            <button
                                className="rounded py-2 px-4 mr-2 mb-2 bg-blue-700 font-light text-white hover:bg-blue-800 flex"
                                onClick={verifyPhoneNumber}
                            >
                                {verifyingPhoneNumber ? (
                                    <div className="-ml-1 mr-3">
                                        <Loader />
                                    </div>
                                ) : (
                                    <ShieldCheck className="mr-3" />
                                )}
                                Verify phone number
                            </button>
                        )}
                        {showVerifyEmail && (
                            <button
                                className="rounded py-2 px-4 mr-2 mb-2 bg-blue-700 font-light text-white hover:bg-blue-800 flex"
                                onClick={verifyEmail}
                            >
                                {verifyingEmail ? (
                                    <div className="-ml-1 mr-3">
                                        <Loader />
                                    </div>
                                ) : (
                                    <ShieldCheck className="mr-3" />
                                )}
                                Verify email address
                            </button>
                        )}
                        {showResetPassword && (
                            <button
                                className="rounded py-2 px-4 mr-2 mb-2 bg-red-700 font-light text-white hover:bg-red-800 flex"
                                onClick={resetUser}
                            >
                                {resettingUser ? (
                                    <div className="-ml-1 mr-3">
                                        <Loader />
                                    </div>
                                ) : (
                                    <ShieldExclamation className="mr-3" />
                                )}
                                Reset password
                            </button>
                        )}
                        {!showVerifyPhoneNumber && !showVerifyEmail && !showResetPassword && (
                            <p>There are no available actions at this time.</p>
                        )}
                    </div>
                    {Object.entries(pageErrors).map(([type, message]) => {
                        if (!message) return null;

                        return (
                            <p className="text-sm text-red-700 mt-1 flex items-center">
                                <ExclamationCircle className="mr-1" />
                                <strong className="mr-1">{type} error:</strong>
                                {message}
                            </p>
                        );
                    })}
                    {successMessage && (
                        <p className="text-sm text-green-700 mt-2 flex items-center">
                            <CheckCircle className="mr-1" />
                            {successMessage}
                        </p>
                    )}
                </>
            )}
        </section>
    );
};

export default UserDetails;
